import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'

import { FormContainer, Layout, OnBoard, SEO } from 'components'

import { formStyle } from 'components/formStyle'

import { HOME_ENDPOINT } from 'constants/apiUrls'

const OnboardingPage = () => {
  const classes = formStyle()
  const loggedUser = useSelector(state => state.userState.user)
  const [onboardingState, setOnboardingState] = useState(false)

  useEffect(() => {
    const getOnboardingState = () => {
      if (loggedUser.onboarded) {
        navigate(HOME_ENDPOINT)
      } else {
        setOnboardingState(true)
      }
    }
    if (loggedUser && !onboardingState) {
      getOnboardingState()
    }
  }, [loggedUser, onboardingState])

  const forms = [
    {
      label: 'Create New Account',
      component: <OnBoard />,
    },
  ]

  return (
    <Layout>
      <SEO title="Onboarding" />
      <div className={classes.formContent}>
        <FormContainer forms={forms} className={classes.formContainer} />
      </div>
    </Layout>
  )
}

export default OnboardingPage
